var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "supplier-unpaid"
  }, [_c('my-nav-bar', {
    attrs: {
      "title": "未付货款",
      "left-text": "返回",
      "left-arrow": "",
      "right-text": "添加欠条"
    },
    on: {
      "click-right": _vm.toSupplierView
    }
  }), _c('van-sticky', {
    staticStyle: {
      "margin-top": "1.22667rem"
    },
    attrs: {
      "offset-top": "1.22667rem"
    }
  }, [_c('van-search', {
    attrs: {
      "placeholder": "请输入供应商名称"
    },
    on: {
      "search": _vm.onSearch
    },
    model: {
      value: _vm.listQuery.supplier_name,
      callback: function callback($$v) {
        _vm.$set(_vm.listQuery, "supplier_name", $$v);
      },
      expression: "listQuery.supplier_name"
    }
  }), _c('van-cell', {
    attrs: {
      "title": "总未付",
      "value": '￥' + _vm.totalUnpaidAmount,
      "value-class": "cell-value",
      "center": ""
    }
  }), _c('van-cell', {
    attrs: {
      "title": "总欠条",
      "value": '￥' + _vm.totalSurplusAmount,
      "value-class": "cell-value",
      "center": ""
    }
  }), _c('van-cell', {
    attrs: {
      "title": "总退货",
      "value": '￥' + _vm.totalReturnAmount,
      "value-class": "cell-value",
      "center": ""
    }
  }), _c('van-cell', {
    attrs: {
      "title": "门店",
      "center": "",
      "is-link": "",
      "value": _vm.storeName,
      "border": false
    },
    on: {
      "click": function click($event) {
        _vm.showStoreActionSheet = true;
      }
    }
  })], 1), _c('van-list', {
    ref: "list",
    staticClass: "list",
    attrs: {
      "finished": _vm.finished
    },
    on: {
      "load": _vm.getList
    },
    model: {
      value: _vm.loading,
      callback: function callback($$v) {
        _vm.loading = $$v;
      },
      expression: "loading"
    }
  }, [_vm.showEmpty ? _c('van-empty', {
    attrs: {
      "description": "暂无数据"
    }
  }) : _vm._e(), _vm.list.length > 0 ? _c('div', {
    staticClass: "tips"
  }, [_vm._v("提示：往左滑动查看欠条")]) : _vm._e(), _vm._l(_vm.list, function (supplier) {
    return _c('van-swipe-cell', {
      key: supplier.id,
      scopedSlots: _vm._u([{
        key: "right",
        fn: function fn() {
          return [_c('van-button', {
            staticStyle: {
              "height": "100%"
            },
            attrs: {
              "square": "",
              "type": "primary",
              "text": "欠条"
            },
            on: {
              "click": function click($event) {
                return _vm.toIouView(supplier);
              }
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('van-cell', {
      attrs: {
        "title": supplier.name,
        "value-class": "cell-value",
        "is-link": "",
        "center": ""
      },
      on: {
        "click": function click($event) {
          return _vm.toPaymentView(supplier.id);
        }
      }
    }, [_c('div', [supplier.unpaid_amount > 0 ? _c('div', [_vm._v("未付：￥" + _vm._s(supplier.unpaid_amount))]) : _vm._e(), supplier.surplus_amount > 0 ? _c('div', [_vm._v("欠条：￥" + _vm._s(supplier.surplus_amount))]) : _vm._e(), supplier.return_amount < 0 ? _c('div', [_vm._v("退货：￥" + _vm._s(supplier.return_amount))]) : _vm._e()])])], 1);
  })], 2), _c('my-dialog', {
    attrs: {
      "title": "添加欠条"
    },
    on: {
      "confirm": _vm.addIouConfirm
    },
    model: {
      value: _vm.showIouDialog,
      callback: function callback($$v) {
        _vm.showIouDialog = $$v;
      },
      expression: "showIouDialog"
    }
  }, [_c('div', {
    staticClass: "iou-container"
  }, [_c('van-cell', {
    attrs: {
      "title": "供应商名称",
      "value": _vm.iouData.supplier_name,
      "center": ""
    }
  }), _c('van-field', {
    attrs: {
      "label": "欠条金额",
      "input-align": "right",
      "placeholder": "请输入欠条金额",
      "type": "number"
    },
    model: {
      value: _vm.iouData.iou_amount,
      callback: function callback($$v) {
        _vm.$set(_vm.iouData, "iou_amount", $$v);
      },
      expression: "iouData.iou_amount"
    }
  }), _c('van-cell', {
    attrs: {
      "title": "欠条日期",
      "value": _vm.iouData.iou_date,
      "is-link": "",
      "center": ""
    },
    on: {
      "click": function click($event) {
        _vm.showDatePicker = true;
      }
    }
  })], 1)]), _c('van-popup', {
    attrs: {
      "position": "bottom"
    },
    model: {
      value: _vm.showDatePicker,
      callback: function callback($$v) {
        _vm.showDatePicker = $$v;
      },
      expression: "showDatePicker"
    }
  }, [_c('van-datetime-picker', {
    attrs: {
      "type": "date",
      "title": "选择日期"
    },
    on: {
      "confirm": _vm.handleSelectDate,
      "cancel": function cancel($event) {
        _vm.showDatePicker = false;
      }
    },
    model: {
      value: _vm.currentDate,
      callback: function callback($$v) {
        _vm.currentDate = $$v;
      },
      expression: "currentDate"
    }
  })], 1), _c('van-action-sheet', {
    attrs: {
      "actions": _vm.stores
    },
    on: {
      "select": _vm.handleSelectStore
    },
    model: {
      value: _vm.showStoreActionSheet,
      callback: function callback($$v) {
        _vm.showStoreActionSheet = $$v;
      },
      expression: "showStoreActionSheet"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }