<template>
  <div class="supplier-unpaid">
    <my-nav-bar
      title="未付货款"
      left-text="返回"
      left-arrow
      right-text="添加欠条"
      @click-right="toSupplierView"
    />
    <van-sticky style="margin-top: 1.22667rem" offset-top="1.22667rem">
      <van-search v-model="listQuery.supplier_name" placeholder="请输入供应商名称" @search="onSearch" />
      <van-cell title="总未付" :value="'￥' + totalUnpaidAmount" value-class="cell-value" center />
      <van-cell title="总欠条" :value="'￥' + totalSurplusAmount" value-class="cell-value" center />
      <van-cell title="总退货" :value="'￥' + totalReturnAmount" value-class="cell-value" center />
      <van-cell title="门店" center is-link :value="storeName" :border="false" @click="showStoreActionSheet = true" />
    </van-sticky>
    <van-list ref="list" v-model="loading" class="list" :finished="finished" @load="getList">
      <van-empty v-if="showEmpty" description="暂无数据" />
      <div v-if="list.length > 0" class="tips">提示：往左滑动查看欠条</div>
      <van-swipe-cell
        v-for="(supplier) in list"
        :key="supplier.id"
      >
        <van-cell
          :title="supplier.name"
          value-class="cell-value"
          is-link
          center
          @click="toPaymentView(supplier.id)"
        >
          <div>
            <div v-if="supplier.unpaid_amount > 0">未付：￥{{ supplier.unpaid_amount }}</div>
            <div v-if="supplier.surplus_amount > 0">欠条：￥{{ supplier.surplus_amount }}</div>
            <div v-if="supplier.return_amount < 0">退货：￥{{ supplier.return_amount }}</div>
          </div>
        </van-cell>
        <template #right>
          <van-button style="height: 100%" square type="primary" text="欠条" @click="toIouView(supplier)" />
        </template>
      </van-swipe-cell>
    </van-list>
    <my-dialog v-model="showIouDialog" title="添加欠条" @confirm="addIouConfirm">
      <div class="iou-container">
        <van-cell title="供应商名称" :value="iouData.supplier_name" center />
        <van-field v-model="iouData.iou_amount" label="欠条金额" input-align="right" placeholder="请输入欠条金额" type="number" />
        <van-cell title="欠条日期" :value="iouData.iou_date" is-link center @click="showDatePicker = true" />
      </div>
    </my-dialog>
    <van-popup v-model="showDatePicker" position="bottom">
      <van-datetime-picker
        v-model="currentDate"
        type="date"
        title="选择日期"
        @confirm="handleSelectDate"
        @cancel="showDatePicker = false"
      />
    </van-popup>

    <van-action-sheet v-model="showStoreActionSheet" :actions="stores" @select="handleSelectStore" />

  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getSupplierUnpaids, addSupplierIou } from '@/api/supplier-unpaid'
import MyDialog from '@/components/my-dialog'
import { toDateString } from '@/utils'
import { getStores } from '@/api/home'
export default {
  name: 'SupplierUnpaid',
  components: { myNavBar, MyDialog },
  data() {
    return {
      loading: false,
      finished: false,
      storeName: '',
      listQuery: {
        page: 0,
        limit: 10,
        store_id: undefined,
        supplier_name: ''
      },
      list: [],
      showEmpty: false,
      totalUnpaidAmount: 0,
      totalSurplusAmount: 0,
      totalReturnAmount: 0,
      showIouDialog: false,
      iouData: {
        supplier_id: '',
        supplier_name: '',
        iou_amount: '',
        iou_date: ''
      },
      showDatePicker: false,
      currentDate: new Date(),
      showStoreActionSheet: false,
      stores: []
    }
  },
  created() {
    this.$store.commit('setKeepAlive', this.$options.name)
    this.$eventBus.$on('handle-iou', res => {
      this.$nextTick(() => {
        setTimeout(() => {
          this.showIouDialog = true
          this.iouData.supplier_id = res.id
          this.iouData.supplier_name = res.name
        }, 100)
      })
    })

    getStores().then(res => {
      this.stores = res.data
    })
  },
  beforeRouteLeave(to, from, next) {
    // 清除缓存
    if (to.path === '/home') {
      this.$store.commit('rmKeepAlive', this.$options.name)
    }
    next()
  },
  methods: {
    getList() {
      this.listQuery.page++
      this.showEmpty = false
      getSupplierUnpaids(this.listQuery).then(res => {
        this.list = this.list.concat(res.data.list)
        this.loading = false
        this.finished = res.data.list.length < this.listQuery.limit
        this.showEmpty = this.list.length === 0
        this.totalUnpaidAmount = res.data.total_unpaid_amount
        this.totalSurplusAmount = res.data.total_surplus_amount
        this.totalReturnAmount = res.data.total_return_amount
      })
    },
    toPaymentView(supplierId) {
      this.$router.push({
        path: 'supplier-unpaid-detail',
        query: {
          supplier_id: supplierId
        }
      })
    },
    reload() {
      this.listQuery.page = 0
      this.list = []
      this.finished = false
      this.show = false
      this.$refs.list.check()
    },
    onSearch() {
      this.reload()
    },
    toSupplierView() {
      this.$router.push({
        path: '/supplier-unpaid-select'
      })
    },
    handleAddIou() {
      this.showIouDialog = true
      this.iouData.iou_amount = ''
      this.iouData.iou_date = ''
    },
    handleSelectDate(date) {
      this.iouData.iou_date = toDateString(date)
      this.showDatePicker = false
    },
    addIouConfirm() {
      this.beginLoad()
      addSupplierIou(this.iouData).then(res => {
        this.success(res.msg)
        this.reload()
      })
    },
    toIouView(supplier) {
      this.$router.push({
        path: '/supplier-unpaid-iou',
        query: {
          supplier_id: supplier.id
        }
      })
    },
    handleSelectStore(store) {
      this.showStoreActionSheet = false
      this.storeName = store.name
      this.listQuery.store_id = store.id
      this.reload()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/color.scss";
  .cell-value {
    color: $amountRedColor;
  }

  .tips {
    padding: 10px;
    color: $grayTextColor;
  }
  .payment-container {
    padding: 10px;
  }
</style>
